import {createSelector} from "@reduxjs/toolkit";
import {useSelector} from "react-redux";
import {useCalculationDetailsQuery} from "../../../store/api";
import {getAllFields} from "./fields-slice";
import {selectAllChoices} from "./choices-slice";
import {selectAllDates} from "./dates-slice";
import {makeId, selectAllChoicesPrices, selectChoicesPricesColumns} from "./choices-prices-slice";
import {processFlightOverride} from "./flight-override";

const selectEntriesParam = createSelector(
    // no selectAllEntries here to avoid reload on reorder
    state => state.calculation.entries.entities,
    entries => Object.values(entries).map(
        ({
             service, occupancyChoices, childChoices, count, exchangeRate, additionalService, group, data, day, duration
         }) => ({
            service, occupancyChoices, childChoices, count, exchangeRate, additionalService, group, data, day, duration
        })
    )
)
const selectFieldParams = createSelector(
    getAllFields,
    ({
         organization: id,
         fromDate,
         toDate,
         daysCount,
         minParticipants,
         maxParticipants,
         marginParticipants,
         currency,
         marginTax,
         dateOverride
     }) => ({
        organization: {id, modelId: "organization/organization"},
        fromDate,
        toDate,
        daysCount,
        minParticipants,
        maxParticipants,
        marginParticipants,
        currency,
        marginTax,
        dateOverride
    })
)
const selectChoices = createSelector(
    selectAllChoices,
    choices => choices.map(
        ({
             id, occupancyType, override, overrideNoFlights, childPrices
         }) => ({
            id, occupancyType, override, overrideNoFlights, childPrices
        })
    )
)
const selectChoiceParams = createSelector(
    selectChoices,
    selectChoicesPricesColumns,
    selectAllChoicesPrices,
    (choices, columns, choicesPrices) =>
        choices.map(({id, occupancyType, override, overrideNoFlights, childPrices = []}) => {
            let overrideData = {}

            for (const [choiceId, groupId, paxId] of columns.filter(([choiceId]) => id === choiceId)) {
                const id = makeId(choiceId, groupId, paxId)
                const {retailPrice, manualFactor} = choicesPrices.find(cp => cp.id === id)
                const value = retailPrice || null === retailPrice ? {retailPrice} :
                    manualFactor || null === manualFactor ? {manualFactor} :
                        undefined

                if (value) {
                    overrideData = {
                        ...overrideData,
                        [groupId]: !paxId ? value : {
                            ...overrideData[groupId],
                            [paxId]: value
                        }
                    }
                }
            }

            return {
                id, occupancyType, override, overrideNoFlights, overrideData,
                childPrices: childPrices.map(([id]) => ({id}))
            }
        })
)
export const selectDatesParams = createSelector(
    state => selectAllDates(state),
    dates => dates.map(({date}) => date)
)
const selectCalculationDetailsParams = createSelector(
    [
        selectEntriesParam,
        selectFieldParams,
        selectChoiceParams,
        selectDatesParams,
    ],
    (entries, fields, occupancyChoices, dates) => {
        const params = {entries, occupancyChoices, dates, ...fields}

        processFlightOverride(params)

        return params
    }
)

export const useCalculationDetails = () => useCalculationDetailsQuery(useSelector(selectCalculationDetailsParams))
