import {DialogController} from "aurelia-dialog";
import {inject} from "aurelia-framework";

@inject(DialogController)
export class ServiceForm
{
    constructor(dialogController)
    {
        this.dialogController = dialogController;
    }

    activate(context)
    {
        this.data = context.data;
        this.title = context.title;
        this.service = context.service;
    }

    submit(object)
    {
        this.dialogController.ok(object);

        return Promise.resolve();
    }
}
