import {createEntityAdapter, createSelector, createSlice} from "@reduxjs/toolkit";
import {useSelector} from "react-redux";
import {loadCalculation} from "./load-calculation";
import {calculationDetailsLoading} from "../../../store/api";
import updateDetails from "./update-details";
import {calculationSettingsLoaded} from "./calculation-settings-loaded";

const choicesAdapter = createEntityAdapter()
const choicesSlice = createSlice({
    name: "calculation/choices",
    initialState: choicesAdapter.getInitialState({
        colSpan: 0,
        hideCalculatedPurchasePrice: false,
        marginFractions: [],
        overrideColumns: [],
        threshold: undefined,
        thresholdMarginTax: undefined,
        thresholdNoMarginTax: undefined,
        marginTax: false,
        flightPrices: false,
        choiceColumns: 0
    }),
    reducers: {
        setChoiceField: {
            prepare: (id, field, value) => ({payload: {id, field, value}, meta: {dirty: true}}),
            reducer(state, {payload: {id, field, value}}) {
                state.entities[id][field] = value
            }
        },
    },
    extraReducers: builder => builder
        .addCase(loadCalculation, (state, {payload: {occupancyChoices, marginTax}}) => {
            const choices = occupancyChoices.map(
                ({
                     id,
                     occupancyType,
                     fare,
                     shortLabel,
                     accommodation,
                     noExternalBooking,
                     override,
                     overrideNoFlights,
                     previousPrice = "",
                     childPrices = []
                 }) => ({
                    id,
                    occupancyType,
                    fare,
                    shortLabel,
                    accommodation,
                    noExternalBooking,
                    override,
                    overrideNoFlights,
                    previousPrice,
                    updating: false,
                    columns: [],
                    marginFractions: [],
                    childPrices: [].concat(childPrices)
                        .sort(({ageFrom: a = -99}, {ageFrom: b}) => b - a)
                        .map(({ageFrom, ageTo, id}) => [id, `Kind ${ageFrom ? ageFrom + " –" : "≤"} ${ageTo}`])
                })
            )

            state.marginTax = marginTax
            state.threshold = marginTax ? state.thresholdMarginTax : state.thresholdNoMarginTax
            state.choiceColumns = occupancyChoices.reduce(
                ((sum, {childPrices}) => sum + childPrices?.length),
                occupancyChoices.length
            )

            choicesAdapter.setAll(
                state,
                !state.flightPrices ? choices : [].concat(
                    choices.map(({id, ...rest}) => ({id, flights: true, ...rest})),
                    choices.map(({id, ...rest}) => ({id: id + "-noflights", flights: false, ...rest}))
                )
            )
        })
        .addCase(updateDetails, (state, {payload: {columnKeys, groupKeys, table}}) => {
            state.overrideColumns = state.useOverrideForAllPax ?
                groupKeys.map(({groupId, colSpan}) => ({groupId, colSpan, border: true, marginPax: true})) :
                columnKeys.map(({groupId, paxId, border, from, to}) => ({groupId, paxId, border, colSpan: 1, from, to}))

            const marginFractions = state.marginFractions.map(({id}) => id)

            choicesAdapter.updateMany(state, state.ids.map(choiceId => {
                const rows = new Set()

                for (const {groupId, paxId} of columnKeys) {
                    for (const id of Object.keys(table[groupId]?.[paxId]?.[choiceId]?.marginFractions ?? {})) {
                        rows.add(id)
                    }
                }

                return {
                    id: choiceId, changes: {
                        updating: false,
                        marginFractions: marginFractions.filter(id => rows.has(id)),
                        columns: columnKeys.map(({key, groupId, paxId, from, to, border}) => {
                            const cell = table[groupId]?.[paxId]?.[choiceId] ?? {}
                            const overrideRetail = table[groupId]?.[choiceId]?.override?.retailPrice ??
                                table[groupId]?.[paxId]?.[choiceId]?.override?.retailPrice
                            const margin = cell.margin ?? cell.marginFractions?.___sum?.[0]

                            return {
                                key, border, from, to, margin, ...cell,
                                overrideRetail: "number" === typeof overrideRetail ? Math.round(100 * overrideRetail) : undefined,
                                color: "number" === typeof margin && "number" === typeof state.threshold ?
                                    (margin < state.threshold ? "red" : "green") : undefined,
                            }
                        })
                    }
                }
            }))
        })
        .addCase(calculationSettingsLoaded, (state, {
            payload: {
                colSpan,
                grossMarginThresholdWithTaxMargin,
                grossMarginThresholdWithoutTaxMargin,
                hideCalculatedPurchasePrice,
                marginFractions = [],
                marginFromGross,
                showPreviousPriceField,
                showSingleRoomUpcharge,
                useOverrideForAllPax,
                flightGroup
            }
        }) => {
            state.colSpan = colSpan
            state.hideCalculatedPurchasePrice = !!hideCalculatedPurchasePrice
            state.showPreviousPriceField = !!showPreviousPriceField
            state.showSingleRoomUpcharge = !!showSingleRoomUpcharge
            state.useOverrideForAllPax = !!useOverrideForAllPax
            state.flightPrices = flightGroup && "" !== flightGroup
            state.marginFractions = [
                {id: "___sum", title: "tourism-journey-calculation.marginComplete"},
                ...marginFractions.map(({identifier: id, title}) => ({id, title})),
                {id: "___rest", title: "tourism-journey-calculation.marginRest"},
                {id: "___marginTax", title: "tourism-journey-calculation.marginTax"}
            ]
            if (marginFromGross) {
                state.thresholdMarginTax = grossMarginThresholdWithTaxMargin
                state.thresholdNoMarginTax = grossMarginThresholdWithoutTaxMargin
                state.threshold = state.marginTax ? state.thresholdMarginTax : state.thresholdNoMarginTax
            }
        })
        .addMatcher(calculationDetailsLoading, state => {
            choicesAdapter.updateMany(state, state.ids.map(id => ({id, changes: {updating: true}})))
        })
})

export default choicesSlice.reducer
export const {setChoiceField} = choicesSlice.actions

export const {
    selectAll: selectAllChoices,
    selectById: selectChoiceById,
    selectIds: selectChoiceIdsWithFlights,
    selectTotal: selectChoicesTotal
} = choicesAdapter.getSelectors(state => state.calculation.choices)

export const useChoice = id => useSelector(state => selectChoiceById(state, id))

const selectChoiceSettings = createSelector(
    state => {
        const {
            colSpan,
            hideCalculatedPurchasePrice,
            showPreviousPriceField,
            showSingleRoomUpcharge,
            useOverrideForAllPax
        } = state.calculation.choices
        return {
            colSpan,
            hideCalculatedPurchasePrice,
            showPreviousPriceField,
            showSingleRoomUpcharge,
            useOverrideForAllPax
        }
    },
    settings => settings
)
export const useChoiceSettings = () => useSelector(selectChoiceSettings)

const selectOverrideColumns = createSelector(
    state => state.calculation.choices.overrideColumns,
    overrideColumns => overrideColumns
)
export const useOverrideColumns = () => useSelector(selectOverrideColumns)

export const selectChoiceLabels = createSelector(
    state => selectAllChoices(state).map(({id, shortLabel, flights}) => [id, shortLabel, flights]),
    labels => Object.fromEntries(labels.map(([id, label, flights]) => [
        id,
        label + ("boolean" === typeof flights ? (flights ? " mit Flug" : " ohne Flug") : "")
    ]))
)
export const useChoiceLabel = id => useSelector(selectChoiceLabels)[id]

export const selectChoiceFlights = createSelector(
    state => selectAllChoices(state).map(({id, flights}) => [id, flights]),
    flights => Object.fromEntries(flights)
)
export const useChoiceFlights = id => useSelector(selectChoiceFlights)[id]

const selectMarginFractionTitle = fraction => createSelector(
    state => state.calculation.choices.marginFractions,
    marginFractions => marginFractions.find(({id}) => fraction === id).title
)
export const useMarginFractionTitle = fraction => useSelector(selectMarginFractionTitle(fraction))

const selectChoiceIds = createSelector(
    selectChoiceIdsWithFlights,
    ids => ids.filter(id => !/-noflights$/.test(id))
)
export const useChoiceIds = () => useSelector(selectChoiceIds)

const selectChoicesCount = createSelector(
    selectChoiceIds,
    ids => ids.length
)
export const useChoicesCount = () => useSelector(selectChoicesCount)
export const useChoiceColumns = () => useSelector(state => state.calculation?.choices?.choiceColumns ?? 0)

const selectChoiceChildPrices = createSelector(
    selectChoiceIds,
    selectAllChoices,
    (ids, choices) => Object.fromEntries(
        ids.map(_id => [_id, choices.find(({id}) => _id === id)?.childPrices])
    )
)
export const useChoiceChildPrices = () => useSelector(selectChoiceChildPrices)
