import {createEntityAdapter, createSelector, createSlice} from "@reduxjs/toolkit";
import {calculationDetailsLoaded} from "../../../store/api";
import {useSelector} from "react-redux";
import {loadCalculation} from "./load-calculation";
import updateDetails from "./update-details";
import {calculationSettingsLoaded} from "./calculation-settings-loaded";

const choicesPricesAdapter = createEntityAdapter()
export const makeId = (choiceId, groupId, paxId) => undefined === paxId ?
    choiceId + '|' + groupId :
    choiceId + '|' + groupId + '|' + paxId

export const choicesPricesSlice = createSlice({
    name: "calculation/choices-prices",
    initialState: choicesPricesAdapter.getInitialState({choiceIds: [], columns: [], flightPrices: false}),
    reducers: {
        setChoicePriceField: {
            prepare: (field, choiceId, groupId, paxId, value) => ({
                meta: {
                    dirty: true
                },
                payload: {
                    id: makeId(choiceId, groupId, paxId),
                    [field]: value
                }
            }),
            reducer(state, {payload: {id, retailPrice, manualFactor}}) {
                const changes = {
                    retailPrice: null,
                    manualFactor: null
                }

                if (undefined !== retailPrice) {
                    changes.retailPrice = "" === retailPrice ? null : retailPrice
                } else {
                    changes.manualFactor = "" === manualFactor ? null : manualFactor
                }

                choicesPricesAdapter.updateOne(state, {id, changes})
            }
        }
    },
    extraReducers: builder => builder
        .addCase(loadCalculation, (state, {payload: {occupancyChoices = []}}) => {
            state.choiceIds = occupancyChoices.map(({id}) => id)

            if (state.flightPrices) {
                state.choiceIds = state.choiceIds.concat(
                    occupancyChoices.map(({id}) => id + "-noflights")
                )
            }
        })
        .addCase(updateDetails, (state, {payload: {columnKeys, columns = {}, table}}) => {
            state.columns = [].concat(
                ...state.choiceIds.map(choiceId => state.useOverrideForAllPax ?
                    Object.keys(columns).map(groupId => [choiceId, groupId]) :
                    columnKeys.map(({groupId, paxId}) => [choiceId, groupId, paxId])
                )
            )
            choicesPricesAdapter.upsertMany(
                state,
                state.columns.map(([choiceId, groupId, paxId]) => {
                    const override = paxId ?
                        table[groupId]?.[paxId]?.[choiceId]?.override :
                        table[groupId]?.[choiceId]?.override
                    const {retailPrice: retailPriceDefault, manualFactor: manualFactorDefault} = override ?? {}
                    return {
                        id: makeId(choiceId, groupId, paxId),
                        retailPriceDefault, manualFactorDefault
                    }
                })
            )
        })
        .addCase(calculationSettingsLoaded, (state, {payload: {useOverrideForAllPax, flightGroup}}) => {
            state.useOverrideForAllPax = useOverrideForAllPax
            state.flightPrices = flightGroup && "" !== flightGroup
        })
})

export const choicesPrices = choicesPricesSlice.reducer
export default choicesPrices

export const {setChoicePriceField} = choicesPricesSlice.actions
export const {
    selectById: selectChoicePriceById,
    selectAll: selectAllChoicesPrices
} = choicesPricesAdapter.getSelectors(state => state.calculation.choicesPrices)

const selectChoicePrice = (choiceId, groupId, paxId) => createSelector(
    state => selectChoicePriceById(state, makeId(choiceId, groupId, paxId)),
    choice => choice ?? {}
)
export const useChoicePrice = (choiceId, groupId, paxId) => useSelector(selectChoicePrice(choiceId, groupId, paxId))

export const selectChoicesPricesColumns = state => state.calculation.choicesPrices.columns;
