import {createEntityAdapter, createSelector, createSlice} from "@reduxjs/toolkit";
import {loadCalculation} from "./load-calculation";
import {calculationDetailsLoading} from "../../../store/api";
import {useSelector} from "react-redux";
import updateDetails from "./update-details";
import {calculationSettingsLoaded} from "./calculation-settings-loaded";

const entriesAdapter = createEntityAdapter({
    selectId: ({identifier}) => identifier
})

const entriesSlice = createSlice({
    name: "calculation/entries",
    initialState: entriesAdapter.getInitialState({
        columns: [],
        currency: "EUR",
        currencies: {},
        groups: ["Allgemein"],
        flightGroup: undefined
    }),
    reducers: {
        addEntry: {
            prepare: (data) => ({
                payload: data,
                meta: {dirty: true}
            }),
            reducer(state, {payload: {service, group, occupancyChoices, identifier, data, duration}}) {
                entriesAdapter.addOne(state, {
                    service,
                    occupancyChoices,
                    group,
                    identifier,
                    data,
                    duration,
                    day: 1,
                    count: 1,
                    additionalService: "additionalService" === group,
                    columns: state.columns
                })
            }
        },

        removeEntry: {
            prepare: id => ({payload: id, meta: {dirty: true}}),
            reducer(state, {payload: id}) {
                entriesAdapter.removeOne(state, id)
            }
        },

        setEntryField: {
            prepare: (id, field, value) => ({payload: {id, field, value}, meta: {dirty: true}}),
            reducer(state, {payload: {id, field, value}}) {
                state.entities[id][field] = value
                if ("group" === field) {
                    state.entities[id].additionalService = "additionalService" === value
                }
            }
        },

        setExchangeRate: {
            prepare: (currency, exchangeRate) => ({payload: {currency, exchangeRate}, meta: {dirty: true}}),
            reducer(state, {payload: {currency, exchangeRate}}) {
                for (const id of state.currencies[currency]) {
                    state.entities[id].exchangeRate = exchangeRate
                }
            }
        },

        toggleOccupancyChoice: {
            prepare: (id, choice, child, checked) => ({payload: {id, choice, child, checked}, meta: {dirty: true}}),
            reducer(state, {payload: {id, choice, child, checked}}) {
                const entity = state.entities[id]
                const field = child ? "childChoices" : "occupancyChoices"
                const set = new Set(entity[field] ?? []);
                if (checked) {
                    set.add(choice ?? child);
                } else {
                    set.delete(choice ?? child);
                }
                entity[field] = Array.from(set.values())
            }
        },

        moveEntry: {
            prepare: (id, targetId) => ({payload: {id, targetId}, meta: {dirty: true}}),
            reducer(state, {payload: {id, targetId}}) {
                state.ids.splice(state.ids.indexOf(id), 1)
                state.ids.splice(state.ids.indexOf(targetId), 0, id)

                if (state.entities[id].group !== state.entities[targetId].group) {
                    state.entities[id].group = state.entities[targetId].group
                }

                state.entities[id].additionalService = "additionalService" === state.entities[id].group
            }
        }
    },
    extraReducers: builder => builder
        .addCase(loadCalculation, (state, {payload: {currency, occupancyChoices, entries}}) => {
            state.currency = currency

            const ids = occupancyChoices.map(({id}) => id)
            entriesAdapter.setAll(
                state,
                entries.map((
                    {service, group, day, duration, count, exchangeRate, occupancyChoices, data, childChoices, additionalService, identifier}
                ) => (
                    {
                        service,
                        group: additionalService ? "additionalService" : state.groups.includes(group) ? group : state.groups[0],
                        flight: state.flightGroup === group,
                        day,
                        duration,
                        count,
                        exchangeRate,
                        identifier,
                        occupancyChoices: (occupancyChoices ?? []).filter(id => ids.includes(id)),
                        data,
                        childChoices,
                        additionalService,
                        columns: []
                    }
                ))
            )
        })

        .addCase(updateDetails, (state, {payload: {entries, table, columnKeys}}) => {
            entriesAdapter.updateMany(state, state.ids.map(id => {
                const {service, provider, edit, currency, exchangeRate, additionalData, reduction} = entries[id] ?? {}

                console.debug('UPDATE DETAILS', service, id);

                return {
                    id, changes: {
                        updating: false,
                        service, provider, additionalData, edit, currency, reduction,
                        exchangeRateDefault: exchangeRate,
                        columns: columnKeys.map(({key, groupId, paxId, border, from, to}) => {
                            const [purchase, purchaseOrigin, retail, retailOrigin, calculatedDuration] = table[groupId]?.[paxId]?.[id] ?? []
                            return {key, border, from, to, purchase, retail, purchaseOrigin, retailOrigin, calculatedDuration}
                        })
                    }
                }
            }))
            state.columns = columnKeys.map(({key, border, from, to}) => ({key, border, from, to}))

            const currencies = new Map
            for (const [id, {currency}] of Object.entries(entries)) {
                if ("string" === typeof currency && state.currency !== currency) {
                    currencies.set(currency, (currencies.get(currency) ?? []).concat(id))
                }
            }
            state.currencies = Object.fromEntries(currencies.entries())
        })

        .addCase(calculationSettingsLoaded, (state, {payload: {groups, flightGroup}}) => {
            state.groups = groups ?? ["Allgemein"]
            state.flightGroup = flightGroup
        })

        .addMatcher(calculationDetailsLoading, state => {
            entriesAdapter.updateMany(state, state.ids.map(id => ({
                id, changes: {
                    updating: true,
                    columns: state.entities[id].columns ?? state.columns
                }
            })))
        })
})

const entries = entriesSlice.reducer
export default entries

export const {
    selectAll: selectAllEntries,
    selectById: selectEntryById,
    selectTotal: selectEntryTotal
} = entriesAdapter.getSelectors(state => state.calculation.entries)

export const {
    addEntry,
    removeEntry,
    setEntryField,
    setExchangeRate,
    toggleOccupancyChoice,
    moveEntry
} = entriesSlice.actions

const selectGroups = state => state.calculation.entries.groups;
export const useGroups = () => useSelector(selectGroups)

const selectEntryIdsByGroups = createSelector(
    state => selectAllEntries(state),
    entries => entries.map(({identifier, group}) => [identifier, group]),
)
const selectEntryIdsByGroup = group => createSelector(
    selectEntryIdsByGroups,
    entries => entries.filter(e => e[1] === group).map(e => e[0])
)
export const useEntryIdsByGroup = group => useSelector(selectEntryIdsByGroup(group))

export const useEntryField = (id, field) => useSelector(state => selectEntryById(state, id)?.[field] ?? "")

const selectCurrencies = createSelector(
    state => Object.entries(state.calculation?.entries?.currencies ?? {}).map(([currency, [id]]) => [currency, id]),
    state => Object.fromEntries(
        Object.entries(state.calculation?.entries?.entities ?? {}).map(([id, {exchangeRateDefault}]) => [id, exchangeRateDefault])
    ),
    (currencies, entries) => currencies.map(([currency, id]) => [currency, entries[id]])
)
export const useCurrencies = () => useSelector(selectCurrencies)

export const useFlightGroup = () => useSelector(state => state?.calculation?.entries?.flightGroup)
