import {createSlice} from "@reduxjs/toolkit";
import {useSelector} from "react-redux";
import {loadCalculation} from "./load-calculation";

export const fieldsSlice = createSlice({
    name: "calculation/fields",
    initialState: {},
    reducers: {
        setField: {
            prepare: (field, value) => ({payload: {field, value}, meta: {dirty: true}}),
            reducer: (state, {payload: {field, value}}) => {
                state[field] = value

                if (["minParticipants", "maxParticipants", "marginParticipants"].includes(field)) {
                    if ("" === value) {
                        state[field] = "1"
                        value = 1
                    } else {
                        value = parseInt(value)
                    }
                }

                switch (field) {
                    case "minParticipants":
                        if (value > parseInt(state.marginParticipants)) {
                            state.marginParticipants = state.minParticipants
                        }
                        if (value > parseInt(state.maxParticipants)) {
                            state.maxParticipants = state.minParticipants
                        }
                        break

                    case "maxParticipants":
                        if (value < parseInt(state.marginParticipants)) {
                            state.marginParticipants = state.maxParticipants
                        }
                        if (value < parseInt(state.minParticipants)) {
                            state.minParticipants = state.maxParticipants
                        }
                        break

                    case "marginParticipants":
                        if (value > parseInt(state.maxParticipants)) {
                            state.marginParticipants = state.maxParticipants
                        } else if (value < parseInt(state.minParticipants)) {
                            state.marginParticipants = state.minParticipants
                        }
                        break

                    case "fromDate":
                        if (value > state.toDate) {
                            state.toDate = value
                        }
                        break

                    case "toDate":
                        if (value < state.fromDate) {
                            state.fromDate = value
                        }
                        break
                }
            }
        },
        clearFields: ({marginTax, organization}) => ({marginTax, organization})
    },
    extraReducers: builder => builder
        .addCase(loadCalculation, (state, {payload}) => {
            const minParticipants = parseInt(payload.minParticipants) || 1
            const maxParticipants = parseInt(payload.maxParticipants) || 1
            const marginParticipants = parseInt(payload.marginParticipants) || 1;

            state.itinerary = payload.itinerary.id
            state.marginTax = state.marginTax ?? payload.marginTax
            state.organization = state.organization ?? payload.organization
            state.id = payload.id
            state.active = payload.active
            state.fromDate = payload.fromDate?.substr(0, 10)
            state.toDate = payload.toDate?.substr(0, 10)
            state.minParticipants = "" + minParticipants
            state.maxParticipants = "" + maxParticipants
            state.marginParticipants = "" + Math.min(maxParticipants, Math.max(minParticipants, marginParticipants))
            state.daysCount = "" + (parseInt(payload.daysCount) || 1)
            state.dynamic = !!payload.dynamic
            state.note = payload.note ?? ""
            state.currency = payload.currency ?? "EUR"
            state.dateOverride = payload.dateOverride
        })
})

export const getField = field => state => state.calculation.fields[field] ?? ""
export const useField = field => useSelector(getField(field))
export const getAllFields = state => state.calculation.fields

export const {setField, clearFields} = fieldsSlice.actions

const fields = fieldsSlice.reducer
export default fields

