import {createSelector, createSlice, isAnyOf} from "@reduxjs/toolkit";
import {Container} from "aurelia-dependency-injection";
import {EventAggregator} from "aurelia-event-aggregator";
import {calculationDetailsLoaded, calculationDetailsLoading, calculationSaved} from "../../../store/api";
import {useSelector} from "react-redux";
import {loadCalculation} from "./load-calculation";
import updateDetails from "./update-details";
import {addEntry, removeEntry} from "./entries-slice";
import {clearFields} from "./fields-slice";
import {calculationSettingsLoaded} from "./calculation-settings-loaded";

const ea = Container.instance.get(EventAggregator)
const CONTROL_UID = "tourism-journey-calculation/calculation"
const isDirtyAction = action => true === action.meta?.dirty

function _makeClean(state) {
    ea.publish("sio_unregister_unsaved_changes", {changesKey: CONTROL_UID})
    return {...state, dirty: false, doAutoSave: false}
}

const stateSlice = createSlice({
    name: "calculation/state",
    extraReducers: builder => builder
        .addCase(clearFields, state => {
            state.view = "entries"
            _makeClean(state)
        })
        .addCase(updateDetails, (state, {payload: {columns, columnKeys, groupKeys}}) => {
            state.loading = false
            state.groupKeys = groupKeys
            state.columnKeys = columnKeys
            state.header = [
                groupKeys.map(({from, to, colSpan}) => [from, to, colSpan]),
                columnKeys.map(({groupId, paxId, border}) => [...columns[groupId].columns[paxId], border, groupId])
            ]
        })
        .addCase(calculationSettingsLoaded, (state, {payload: {colSpan, displayColumns, autoSave, hideOccupancyChoices}}) => {
            state.colSpan = colSpan ?? 0
            state.displayColumns = displayColumns ?? []
            state.autoSave = !!autoSave
            state.hideOccupancyChoices = !!hideOccupancyChoices
        })
        .addMatcher(isDirtyAction, state => {
            ea.publish("sio_register_unsaved_changes", {changesKey: CONTROL_UID})
            state.dirty = true
        })
        .addMatcher(isAnyOf(loadCalculation, calculationSaved), state => _makeClean(state))
        .addMatcher(calculationDetailsLoading, state => {
            state.loading = true
        })
        .addMatcher(isAnyOf(addEntry, removeEntry), state => {
            state.doAutoSave = true
        }),
    initialState: {
        dirty: false,
        view: "entries",
        loading: false,
        autoSave: false,
        doAutoSave: false,
        hideOccupancyChoices: false,
        colSpan: 0,
        displayColumns: [],
        columnKeys: [],
        groupKeys: [],
        header: [[], []]
    },
    reducers: {
        setView(state, {payload}) {
            state.view = payload
        },
        makeClean: state => _makeClean(state)
    }
})

export const state = stateSlice.reducer
export const {setView, makeClean} = stateSlice.actions

const selectState = createSelector(state => state.calculation.state, state => state)
const useState = () => useSelector(selectState)

export const useDirty = () => !!useState().dirty
export const useView = () => useState().view
export const useLoading = () => useState().loading
export const useAutoSave = () => useState().autoSave
export const useDoAutoSave = () => useState().doAutoSave
export const useHideOccupancyChoices = () => useState().hideOccupancyChoices
export const useDisplayColumns = () => useState().displayColumns
export const useHeader = () => useState().header
export const useColSpan = () => useState().colSpan
export const useColumnKeys = () => useState().columnKeys
export const useColumnCount = () => useColumnKeys().length

export default state
